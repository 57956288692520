// **********************************************
// Waffl Grid 1.0
// **********************************************

// Imports
// ------
import offset from './offset';
import ordering from './ordering';
import collapse from './collapse';
import mobilePadding from './mobilePadding';
import visibility from './visibility';
import breakpoints from './breakpoints';
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { pull, pullOnly, push, pushOnly } from './pushPull';

// Exports
// ------
const sharedStyles = css`
	display: flex;
`;

export const Column = styled.div(
	(props) => css`
		${sharedStyles}
		flex-direction: column;
		flex-grow: 1;

		padding-left: calc(${props.theme.gutterSmall} / 2);
		padding-right: calc(${props.theme.gutterSmall} / 2);

		${breakup.medium`
			padding-left: calc(${props.theme.gutterSmall} / 2);
			padding-right: calc(${props.theme.gutterSmall} / 2);
		`}

		${breakup.large`
			padding-left: calc(${props.theme.gutterLarge} / 2);
			padding-right: calc(${props.theme.gutterLarge} / 2);
		`}

		${props.maxHeight &&
		css`
			height: 100%;
		`}

    /* MobilePadding */
    ${mobilePadding}

    /* Ordering */
    ${ordering}

    /* Breakpoints */
    ${breakpoints}

    /* Push Columns */
    ${push}
    ${pushOnly}

    /* Pull Columns */
    ${pull}
    ${pullOnly}

    /* Offset Columns */
    ${offset}

    /* Visibility */
    ${visibility}
	`
);

export const Row = styled.div(
	(props) => css`
		${sharedStyles}
		flex-direction: row;
		flex-wrap: wrap;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-flow: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: ${props.theme.maxGridSize ? props.theme.maxGridSize : '144rem'};
		margin: 0 auto;

		/* Want to get rid of that pesky margin? */
		${collapse}

		${props.maxHeight &&
		css`
			height: 100%;
		`}

    ${props.maxHeightDesktop &&
		css`
			${breakup.large` height: 100%; `}
		`}

    /* Want the grid full width of the screen? */
    ${props.isExpanded &&
		css`
			max-width: 100%;
		`}

    /* Vertically position top content */
    ${props.isTop &&
		css`
			align-items: flex-start;
			height: 100%;
		`}

    /* Vertically Center content */
    ${props.isCenter &&
		css`
			align-items: center;
			height: 100%;
		`}

		${props.isCenterDesktop &&
		css`
			${breakup.large`
				align-items: center;
				height: 100%;
			`}
		`}
    /* Vertically position bottom content */
    ${props.isBottom &&
		css`
			align-items: flex-end;
			height: 100%;
		`}

    /* Align content to bottom */
    ${props.isBottom &&
		css`
			align-items: flex-end;
		`}

    /* Reverse ordering of content */
    ${props.isReversed &&
		css`
			flex-direction: row-reverse;
		`}

    /* Make content equalised */
    ${props.isEqual &&
		css`
			align-items: stretch;

			> ${Column} {
				display: flex;
			}
		`}

    /* Want a vertical grid? */
    ${props.vertical &&
		css`
			flex-direction: column;
		`}
	`
);
