// Imports
// ------
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// Component
// ------
function PageTransition({ children, status, entry, exit }) {
	const isExiting = status === 'exiting';
	const hasEntered = status === 'entered';
	const isEntering = status === 'entering';
	const isInitial = hasEntered ? hasEntered : isEntering;

	const transitionProps = {
		initial: isExiting ? { opacity: 1 } : { opacity: 0 },
		animate: isExiting ? { opacity: 0 } : { opacity: 1 },
		transition: { duration: 1 },
	};

	return (
		<AnimatePresence>
			<motion.div {...transitionProps}>{children}</motion.div>
		</AnimatePresence>
	);
}

export default React.memo(PageTransition);
