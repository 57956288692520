// Imports
// ------
import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

// Component
// ------
function Seo({ content, schema, postOverride }) {
	// NOTE • Data
	const data = useStaticQuery(graphql`
		query GlobalSEO {
			global: datoCmsSite {
				seo: globalSeo {
					facebook: facebookPageUrl
					twitter: twitterAccount

					fallback: fallbackSeo {
						title
						image {
							url
						}
						desc: description
						card: twitterCard
					}
				}
			}
		}
	`);

	const fb = data.global.seo.fallback;

	// NOTE • Check is content is filled in or not
	const nothing = undefined || null;
	const toUse = content == nothing ? fb : content;
	const check = toUse.image == nothing ? fb : content;
	const imageToUse = check == nothing ? fb.image : check.image;

	// NOTE • Post overrider
	const postExt = postOverride ? ` • ${postOverride}` : '';

	return (
		<Helmet>
			<title>{`${toUse.title}${postExt}`}</title>
			<meta name='description' content={toUse.desc} />
			{/* ANCHOR • OpenGraph Meta */}
			<meta property='og:title' content={`${toUse.title}${postExt}`} />
			<meta property='og:description' content={toUse.desc} />
			<meta property='og:image' content={imageToUse.url} />
			<meta property='og:type' content={toUse.card} />
			<meta
				name='google-site-verification'
				content='cXr6HHGjkBj2K8zwnWAEfqfvV8VPbQXC-6hF4LnmqD4'
			/>

			{schema && <script type='application/ld+json'>{schema}</script>}
		</Helmet>
	);
}

export default Seo;
