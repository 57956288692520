// Imports
// ------
import { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
const mobilePadding = (props) => css`
	${props.mpad &&
	css`
		padding-left: ${props.theme.gutterSmall};
		padding-right: ${props.theme.gutterSmall};

		${breakup.medium`
            padding-left: calc(${props.theme.gutterSmall} / 2);
            padding-right: calc(${props.theme.gutterSmall} / 2);
        `}

		${breakup.large`
            padding-left: calc(${props.theme.gutterLarge} / 2);
            padding-right: calc(${props.theme.gutterLarge} / 2);
        `}
	`}
`;

export default mobilePadding;
